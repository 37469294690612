<template>
  <div>
    <v-app>
      <v-container>
        <div class="page-card">
          <v-row>
            <v-col md="12">
              <v-row>
                <v-col xl="4" lg="5" md="6">
                  <v-text-field
                    v-model="search"
                    append-icon="search"
                    label="Ara"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col xl="2" lg="3" md="4 ml-auto">
                  <button
                    type="button"
                    v-if="isShow"
                    class="btn btn-primary"
                    @click="goAddOrganizations()"
                  >
                    Organizasyon Ekle
                  </button>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="12">
              <v-data-table
                :headers="(globaladmin || cro) ?headers:headers2"
                :items="desserts2"
                :search="search"
                :footer-props="{
                  'items-per-page-options': [20, 30, 40, -1],
                }"
                :items-per-page="20"
                :loading="loadings.table"
                loading-text="Yükleniyor..."
              >
                <template v-slot:[`item.edit`]="{ item }" v-if="isShow">
                  <v-icon
                    class=" fa-pencil-blue"
                    @click="goOrganizatonEdit(item)"
                  >
                    fas fa-pencil-alt
                  </v-icon>
                </template>
                <template v-slot:[`item.action`]="{ item }">
                  <v-icon
                    class=" text-warning"
                    @click="goOrganizationView(item)"
                  >
                    fas fa-chevron-circle-right
                  </v-icon>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-app>
  </div>
</template>

<style lang="scss">
//@import "@/assets/sass/pages/wizard/wizard-3.scss";
</style>

<script>
//import KTCodePreview from "@/view/content/CodePreview.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
//import KTUtil from "@/assets/js/components/util";
//import KTWizard from "@/assets/js/components/wizard";
//import Swal from "sweetalert2";
import { SET_SELECTEDPAGEITEMNAME } from "@/core/services/store/selectedpageitem.module";
import ApiService from "@/core/services/api.service";
import { mapGetters } from "vuex";
export default {
  name: "organizationlist",
  data() {
    return {
      globaladmin: false,
      cro: false,
      user: false,
      isShow: false,
      loadings: {
        table: false,
      },
      search: "",
      desserts2: [
        {
          organizationName: "",
          organizationType: "",
          country: "",
          city: "",
          landPhone: "",
          detay: "",
          edit: "",
        },
        // {
        //   organizasyonName: "",
        //   tip: "",
        //   country: "",
        //   city: "",
        //   landPhone: "",
        //   detay: "",
        //   edit:"",
        // },
      ],
      headers: [
        {
          text: "Organizasyon Adı",
          align: "left",
          value: "organizationName",
        },
        { text: "Tip", value: "organizationType" },
        { text: "Ülke", value: "country" },
        { text: "İl", value: "city" },
        { text: "Telefon", value: "landPhone" },
        { text: "Düzenle", value: "edit", sortable: false },
        { text: "Detay", value: "action", sortable: false },
      ],
       headers2: [
        {
          text: "Organizasyon Adı",
          align: "left",
          value: "organizationName",
        },
        { text: "Tip", value: "organizationType" },
        { text: "Ülke", value: "country" },
        { text: "İl", value: "city" },
        { text: "Telefon", value: "landPhone" },
        { text: "Detay", value: "action", sortable: false },
      ],
      
    };
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Organizasyon Listesi", route: "/organizationlist" },
    ]);
    this.getOrganizationlist();
    //console.log("global gercek ne geldi,", this.isGlobalAdmin);
    this.globaladmin = this.isGlobalAdmin;
    this.cro = this.isCro;
    this.user = this.isNormalUser;
    // console.log("global admin ne geldi", this.globaladmin);
    this.isShow = this.globaladmin;
  },
  methods: {
    goOrganizatonEdit(item) {
      localStorage.setItem("itemler", JSON.stringify(item));
      this.$store.dispatch(SET_SELECTEDPAGEITEMNAME, "Organizasyon Listesi");
      this.$router.push({ name: "organizationsedit" });
    },
    goOrganizationView(item) {
      localStorage.setItem("itemler", JSON.stringify(item));
      this.$store.dispatch(SET_SELECTEDPAGEITEMNAME, "Organizasyon Listesi");
      this.$router.push({ name: "organizationsview" });
    },

    goAddOrganizations() {
      this.$store.dispatch(SET_SELECTEDPAGEITEMNAME, "Organizasyon Listesi");
      this.$router.push({ name: "organizations" });
    },
    getOrganizationlist() {
      this.loadings.table = true;
      ApiService.get("/organizations")
        .then(({ data }) => {
          console.log("organizasyon listesi geldi", data.result);
          this.desserts2 = data.result;
          //console.log("dessert", this.desserts2);
        })
        .catch((err) => {
          console.log("error", err);
        })
        .then(() => {
          this.loadings.table = false;
        });
    },
    getCurrentid(item) {
      // this.$emit('getCurrentid',item);
      //console.log("item", item);
      localStorage.setItem("itemler", JSON.stringify(item));
    },
  },
  computed: {
    ...mapGetters(["isGlobalAdmin", "isCro", "isNormalUser"]),
    tab: {
      set(tab) {
        this.$router.replace({ query: { ...this.$route.query, tab } });
      },
      get() {
        console.log("query", this.$route.query.tab);
        return this.$route.query.tab;
      },
    },
  },
};
</script>
